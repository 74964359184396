<!-- template -->
<template>
  <div class="sign__page item__column item__ac">
    <h3 class="mb-4">Kassa Login</h3>
    <v-form
        @submit.prevent="loginRequest"
        ref="form"
        class="sign__page__block"
    >
      <v-text-field
          v-model="login"
          label="E-mail"
          required
          outlined
          class="input"
      ></v-text-field>
      <v-text-field
          v-model="password"
          label="Пароль"
          required
          outlined
          class="input"
          type="password"
      ></v-text-field>

      <v-btn
          type="submit"
          color="black"
          class="mb-4 button"
          style="color:white"
      >
        Вход
      </v-btn>
    </v-form>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeLang: 1,
      loginRules: [
        v => !!v || 'Заполните поле',
        v => /.+@.+\..+/.test(v) || 'Не правильный Email',
      ],
      loginEngRules: [
        v => !!v || 'Fill line',
        v => /.+@.+\..+/.test(v) || 'Incorrect email',
      ],
      email: '',
      passwordRules: [
        v => !!v || 'Заполните поле'
      ],
      passwordEngRules: [
        v => !!v || 'Fill line'
      ],
      login: '',
      password: '',
      user: {
        role: ''
      },
      content: {
        content: ''
      }
    }
  },
  mounted() {

  },
  watch: {
    activeLang(val) {
      localStorage.setItem('lang', val);
      this.getContentWord(val);
    }
  },
  methods: {
    getLoginRule() {
      return this.activeLang == 1 ? this.loginRules : this.loginEngRules;
    },
    getPasswordRule() {
      return this.activeLang == 1 ? this.passwordRules : this.passwordEngRules;
    },
    getContentWord(val) {
      this.loading = true;
      let link = 'lang'
      this.$axios({
        method: "get",
        url:
            this.$API_URL +
            link,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          'Accept-Language': val == 1 ? 'ru' : 'en'
        },
      })
          .then((response) => {
            this.content = response.data;
          })
          .catch((error) => {
            console.log(error);
          });
    },
    loginRequest() {
      let obj = {
        email: this.login,
        password: this.password,
      }
      this.$axios({
        method: 'post',
        url: this.$API_URL + 'login',
        data: obj
      })
          .then((response) => {
            localStorage.setItem('access_token', response.data.token)
            localStorage.setItem('role', response.data.role)
            this.$router.push('/cash')
          })
          .catch((error) => {
            let errors = error.response.data.errors;
            for (let variable in errors) {
              this.$toast.open({
                message: errors[variable][0],
                type: "warning",
                position: "bottom",
                duration: 4000,
                queue: true,
              });
              continue;
            }
          });
    }
  }
}
</script>

<style scoped lang="scss">

.sign__page--title {
  font-size: 28px;
  font-family: 'Montserrat';
  font-weight: bold;
}

.mdi-contacts {
  margin-bottom: 20px;
  font-size: 30px;
}

.sign__page__top {
  align-self: center;
  text-align: center;
  width: 80%;
  font-size: 10px;
  font-weight: bold;
}

.sign__page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .v-btn {
    text-transform: lowercase !important;
  }
  @media only screen and (max-width: 764px) {
    width: 100%;
  }

  .sign__page__title {
    font-weight: bold;
    font-size: 26px;
    margin-bottom: 20px;
    color: #333;
    @media only screen and (max-width: 900px) {
      font-size: 18px;
    }
  }

  .sign__page__block {
    display: flex;
    flex-direction: column;
    width: 400px;
    @media only screen and (max-width: 900px) {
      width: 90%;
    }

    input {
      width: 100px;
      padding: 20px;
      margin-bottom: 20px;
      border: 1px solid #ccc;
      @media only screen and (max-width: 764px) {
        width: 100%;

      }
    }

    ::placeholder {
      color: #333;
    }

    button {
      cursor: pointer;
      text-transform: uppercase;
      width: 100%;
      outline: none;
      border: none;
      background-color: cornflowerblue;
      padding: 20px;
      @media only screen and (max-width: 764px) {
        width: 100%;
      }

      p {
        color: white;
        font-weight: bold;
        margin-bottom: 0;

      }
    }

    button:hover {
      background-color: #285bb6;
    }
  }

}

.active__lang {
  color: blue;
}

.sign__page--logo {
  object-fit: cover;
  height: 50px;
  width: 249px;
  margin-bottom: 20px;
}
</style>
